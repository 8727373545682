import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Dropdown = ({ options, onSelect, width, color, backgroundColor }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSelect = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <FormControl sx={{ width: width || (isMobile ? "100%" : "220px") }}>
      <Select
        value={selectedOption}
        onChange={handleSelect}
        sx={{
          width: "100%",
          minWidth: "220px",
          borderRadius: "100px",
          backgroundColor: backgroundColor || "rgba(0, 0, 0, 0.002)",
          color: color || "#333",
          cursor: "pointer",
          height: "3rem",

          "& svg": {
            color: "rgb(50,69,191)",
          },
        }}
        IconComponent={ExpandMoreIcon}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Dropdown;
