import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BubbleLoader from "../../component/Loader/BubbleLoader";
import Button from "../../component/button/Button";
import "./OTPVerification.css";
import { postAPIHandlerWithoutToken } from "../../config/service";
import { toast } from "react-hot-toast";
import AuthLayout from "../../layout/AuthLayout";

const OTPVerification = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(true);
  const [email, setEmail] = useState(location.state?.email);
  const [otpFromForgotPassword, setOtpFromForgotPassword] = useState(
    location.state?.otp
  );
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleChange = (index, e) => {
    let { value } = e.target;
    if (value === "" && index > 0) {
      inputRefs[index - 1].current.focus();
    } else if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const enteredOTP = inputRefs.map((ref) => ref.current.value).join("");
    if (enteredOTP == otpFromForgotPassword) {
      setLoading(true);
      try {
        const response = await postAPIHandlerWithoutToken({
          endPoint: "verifyOTP",
          dataToSend: {
            mobileNumber: email.toLocaleLowerCase(),
            otp: enteredOTP,
          },
        });
        console.log(response.data);
        navigate("/resetpassword", {
          replace: true,
          state: {
            email,
          },
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to verify OTP. Please enter correct  OTP.");
      }
      setLoading(false);
    } else {
      toast.error("Invalid OTP");
    }
  };

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  useEffect(() => {
    const storedTimer = localStorage.getItem("otpTimer");
    if (storedTimer) {
      setTimer(parseInt(storedTimer, 10));
    }
    let intervalId;
    if (timerActive) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            setTimerActive(false);
            clearInterval(intervalId);
            localStorage.removeItem("otpTimer");
            return 60;
          } else {
            localStorage.setItem("otpTimer", prevTimer - 1);
            return prevTimer - 1;
          }
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [timerActive]);

  const handleResendOTP = async () => {
    setTimerActive(true);
    try {
      setIsUpdating(true);
      const response = await postAPIHandlerWithoutToken({
        endPoint: "forgotPassword",
        dataToSend: {
          email: email.toLocaleLowerCase(),
        },
      });
      if (response.data.responseCode === 200) {
        const { otp } = response.data.result;
        setOtpFromForgotPassword(otp);
        setEmail(email.toLocaleLowerCase());
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("Error while resending OTP:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <AuthLayout>
      <div className="login-content login-content-right">
        <form className="admin-login-content" onSubmit={handleSubmit} action="">
          <div className="admin-login-div">
            <h2 className="admin-login-text">Verify OTP</h2>
            <p className="admin-login-msg">OTP has been sent to {email}</p>
          </div>
          <div className="otp-input-container">
            <div>
              {inputRefs.map((ref, index) => (
                <input
                  key={index}
                  ref={ref}
                  className="otp-input"
                  maxLength={1}
                  onKeyDown={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (
                      e.key === "Backspace" &&
                      e.target.value === "" &&
                      index > 0
                    ) {
                      console.log("here");
                      inputRefs[index - 1].current.focus();
                    }
                    if (!re.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => handleChange(index, e)}
                  required
                />
              ))}
            </div>
            {timerActive && (
              <div className="timer-container">Resend after : {timer}s</div>
            )}
          </div>
          <div className="login-button-div">
            <p className="admin-login-msg" style={{ textAlign: "center" }}>
              If you don't receive any OTP ?
              {timerActive ? (
                ` Resend OTP`
              ) : (
                <Link
                  onClick={handleResendOTP}
                  className="forgot-password-link"
                >
                  {" "}
                  Resend OTP
                </Link>
              )}
            </p>

            <Button
              backgroundColor="rgb(50,69,191)"
              color="white"
              width="572px"
              height="60px"
              borderRadius="100px"
              border="none"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <BubbleLoader color="#FFF" size="1rem" />
              ) : (
                "VERIFY OTP"
              )}
            </Button>
          </div>
          <div className="forgot-password-div">
            <Link
              to={"/"}
              style={{ textAlign: "center", textDecoration: "none" }}
              className="forgot-password-link"
            >
              <p className="forgot-password-text">Back to Login</p>
            </Link>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default OTPVerification;
