// export const baseurl = "http://localhost:2081";
export const baseurl = "https://node-propertyleads.mobiloitte.io";

const url = `${baseurl}/api`;

export const ApiConfig = {
  //AUNTHENTICATION && ADMIN
  userLogin: `${url}/admin/userLogin`,
  forgotPassword: `${url}/admin/resendOTP`,
  verifyOTP: `${url}/admin/verifyOTP`,
  resetPassword: `${url}/admin/resetPassword`,
  userSignup: `${url}/admin/signup`,
  updateProfile: `${url}/admin/updateProfile`,
  viewProfile: `${url}/admin/viewProfile`,
  changePassword: `${url}/admin/changePassword`,
  uploadFile: `${url}/admin/uploadFile`,
  userRegistrationUsingFile: `${url}/admin/userRegistrationUsingFile`,
  getAllAdsAccountsAndRolePages: `${url}/admin/getAllAdsAccountsAndRolePages`,

  //DASHBOARD
  listUserDetailsByType: `${url}/admin/listUserDetailsByType`,
  listManagersByVP: `${url}/admin/listManagersByVP`,
  listExecutivesByManagerOrVP: `${url}/admin/listExecutivesByManagerOrVP`,

  //DEVELOPER MANAGEMENT
  addDeveloper: `${url}/admin/addDeveloper`,
  updateDeveloper: `${url}/admin/updateDeveloper`,
  listAllDevelopers: `${url}/admin/listAllDevelopers`,
  getDeveloperById: `${url}/admin/getDeveloperById`,

  //PROPERTY MANAGEMENT
  addProperty: `${url}/admin/addProperty`,
  getAllProperty: `${url}/admin/getAllProperty`,
  updateProperty: `${url}/admin/updateProperty`,
  viewPropertyDetails: `${url}/admin/viewPropertyDetails`,
  getAllUserProperty: `${url}/admin/getAllUserProperty`,

  //BROADCAST MANAGEMENT
  addBroadcast: `${url}/admin/addBroadcast`,
  updateBroadcast: `${url}/admin/updateBroadcast`,
  getBroadcastById: `${url}/admin/getBroadcastById`,
  listAllBroadcasts: `${url}/admin/listAllBroadcasts`,

  //LEAD MANAGEMENT
  getAllLeads: `${url}/executive/getAllLeads`,
  getLeadsDetails: `${url}/executive/getLeadsDetails`,
  getAllLeadHistory: `${url}/executive/getAllLeadHistory`,
  getLeadsFromFacebook: `${url}/executive/getLeadsFromFacebook`,
  getAllLeadsByExecutive: `${url}/executive/getAllLeadsByExecutive`,
  markLeadRequestUsingFile: `${url}/executive/markLeadRequestUsingFile`,
  getAllLeadsRequests: `${url}/executive/getAllLeadsRequests`,
  reassignExecutive: `${url}/executive/reassignExecutive`,
  reassignPreSalesExecutive: `${url}/executive/reassignPreSalesExecutive`,
  reassignExecutiveToMultipleLeads: `${url}/executive/reassignExecutiveToMultipleLeads`,
  reassignPreSaleExecutiveToMultipleLeads: `${url}/executive/reassignPreSaleExecutiveToMultipleLeads`,
  getAllCallDisposition: `${url}/executive/getAllCallDispositionDetails`,

  // STATIC CONTENT MANAGEMENT
  getAllStaticContent: `${url}/staticContent/getAllStaticContent`,
  getAllStaticContentByType: `${url}/staticContent/getAllStaticContentByType`,
  createStaticContent: `${url}/staticContent/createStaticContent`,
  updateStaticContent: `${url}/staticContent/updateStaticContent`,

  // TOKEN MANAGEMENT
  addAccessToken: `${url}/admin/addAccessToken`,
  getAllAccessToken: `${url}/admin/getAllAccessToken`,

  //REPORT MANAGEMENT
  generateTableReport: `${url}/executive/generateTableReport `,

  //URL MANAGEMENT
  getAllExternalUrl: `${url}/admin/getAllExternalUrl`,
  addexternalUrl: `${url}/admin/addexternalUrl`,
  updateExternalUrl: `${url}/admin/updateExternalUrl`,
};
