import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { getAPIHandler, postAPIHandler } from "../../config/service";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { accessTokenSchema } from "../../schemas";
import ButtonComponent from "../../component/Button";
import Input from "@mui/joy/Input";

const TokenManagement = () => {
  const [updatedAtIST, setUpdatedAtIST] = useState(null);

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await postAPIHandler({
        endPoint: "addAccessToken",
        dataToSend: {
          accessToken: values.accessToken,
          appId: values.appId,
          appSecret: values.appSecret,
        },
      });
      console.log(response.data);
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
  };

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getAllAccessToken",
      });

      // Convert updatedAt to IST (Indian Standard Time)
      if (response.data.result && response.data.result[0]?.updatedAt) {
        const updatedAt = new Date(response.data.result[0].updatedAt);
        const istTime = updatedAt.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
        });
        setUpdatedAtIST(istTime); 
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <MainLayout>

    
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" padding="1rem">
          <Typography variant="h4" sx={{ fontWeight: 700 }}>
            Token Management
          </Typography>
        </Box>

        <Box sx={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper
            elevation={0}
            sx={{ padding: "15px", overflowY: "auto", scrollbarWidth: "none" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography
                variant="h5"
                sx={{ marginLeft: "1rem", fontWeight: 800 }}
              >
                Renew Token
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginRight: "1rem", fontStyle: "bold", color: "black" }}
              >
                {updatedAtIST ? `Last Updated: ${updatedAtIST}` : "Loading..."}
              </Typography>
            </Box>

            <Typography variant="body1" sx={{ mb: 2 , ml: 3 }}>
              Follow these steps to generate an access token for posting ads:
            </Typography>

            <List sx={{ paddingLeft: 3 }}>
              {/* First bullet point */}
              <ListItem>
                <ListItemText
                  primary="1. Create a Facebook App"
                  secondary={
                    <>
                      <ul>
                        <li>
                          Go to the Facebook for Developers page and log in with
                          your Facebook account.
                        </li>
                        <li>
                          Click on "Create App" and follow the steps to create a
                          new app.
                        </li>
                        <li>
                          Choose Business as the app type since you are working
                          with ads.
                        </li>
                        <li>
                          Provide the necessary details (App Name, Email, etc.),
                          then click "Create App ID".
                        </li>
                      </ul>
                    </>
                  }
                />
              </ListItem>

              {/* Second bullet point */}
              <ListItem>
                <ListItemText
                  primary="2. Add Facebook Marketing API"
                  secondary={
                    <>
                      <ul>
                        <li>
                          Once your app is created, go to the app dashboard.
                        </li>
                        <li>
                          In the left-hand menu, find "Add a Product" and select
                          "Marketing API".
                        </li>
                        <li>Click "Set Up" under the Marketing API.</li>
                      </ul>
                    </>
                  }
                />
              </ListItem>

              {/* Third bullet point */}
              <ListItem>
                <ListItemText
                  primary="3. Generate an Access Token"
                  secondary={
                    <>
                      <ul>
                        <li>
                          In your app dashboard, go to Tools &gt; Graph API
                          Explorer.
                        </li>
                        <li>Select your app from the dropdown list.</li>
                        <li>Select "Get User Access Token".</li>
                        <li>
                          Choose permissions like <code>ads_management</code>,{" "}
                          <code>ads_read</code>, and{" "}
                          <code>business_management</code>.
                        </li>
                        <li>
                          Click "Generate Access Token" and log in to give
                          permissions to your app.
                        </li>
                      </ul>
                    </>
                  }
                />
              </ListItem>

              {/* Fourth bullet point */}
              <ListItem>
                <ListItemText
                  primary="4. Get Extended Permissions (Optional)"
                  secondary={
                    <>
                      <ul>
                        <li>
                          If you need long-term access (since user tokens
                          expire), generate a Long-Lived Access Token.
                        </li>
                      </ul>
                    </>
                  }
                />
              </ListItem>
            </List>

            {/* Form is inserted here */}
            <Box sx={{ mt: 3, mb: 3, ml: 3 }}>
              <Typography variant="h6" sx={{ fontWeight: 400 , mb: 3}}>
                Enter Access Token Details to generate a Long-Lived Access
                Token:
              </Typography>
              <Box>
                <Formik
                  initialValues={{
                    accessToken: "",
                    appId: "",
                    appSecret: "",
                  }}
                  validationSchema={accessTokenSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box mb={2} width="100%" maxWidth="500px">
                          <Field
                            as={Input}
                            type="text"
                            name="accessToken"
                            placeholder="Enter Access Token"
                            fullWidth
                          />
                          <ErrorMessage
                            name="accessToken"
                            component="div"
                            className="error"
                          />
                        </Box>
                        <Box mb={2} width="100%" maxWidth="500px">
                          <Field
                            as={Input}
                            type="text"
                            name="appId"
                            placeholder="Enter App ID"
                            fullWidth
                          />
                          <ErrorMessage
                            name="appId"
                            component="div"
                            className="error"
                          />
                        </Box>
                        <Box mb={2} width="100%" maxWidth="500px">
                          <Field
                            as={Input}
                            type="text"
                            name="appSecret"
                            placeholder="Enter App Secret"
                            fullWidth
                          />
                          <ErrorMessage
                            name="appSecret"
                            component="div"
                            className="error"
                          />
                        </Box>

                        <Box
                          mb={2}
                          width="100%"
                          display="flex"
                          justifyContent="center"
                        >
                          <ButtonComponent
                            type="submit"
                            name="Submit"
                            disabled={isSubmitting}
                            height="43px"
                            fullWidth
                          />
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>

            {/* Fifth bullet point */}
            <List sx={{ paddingLeft: 3 }}>
              <ListItem>
                <ListItemText
                  primary="5. Use the Access Token to Post Ads"
                  secondary={
                    <>
                      <ul>
                        <li>
                          With the access token, use the Facebook Marketing API
                          to create ads.
                        </li>
                        <li>
                          Make a POST request to <code>/adaccount_id/ads</code>{" "}
                          to create an ad.
                        </li>
                        <li>
                          Ensure your app has the necessary permissions and is
                          authorized to manage Facebook Ads.
                        </li>
                      </ul>
                    </>
                  }
                />
              </ListItem>
            </List>
          </Paper>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default TokenManagement;
