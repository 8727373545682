import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Checkbox,
  CircularProgress,
  Grid,
  Pagination,
  Tooltip,
} from "@mui/material";
import { getAPIHandler } from "../../config/service";
import DataNotFound from "../DataNotFound";
import ReassignPop from "../Popup/ReassignPresalePopup";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(50,69,191)",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(50,69,191, 0.05)",
  },
}));

export default function CustomizedTables(props) {
  const {
    searchQuery,
    fromDate,
    toDate,
    checkBox,
    selectedIds,
    onCheckboxChange,
  } = props;
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getData();
  }, [searchQuery, fromDate, toDate, page]);

  const getData = async () => {
    setLoading(true);
    try {
      const paramsDataForLeads = {
        propertyName: searchQuery || undefined,
      };
      const responseFromFirstAPI = await getAPIHandler({
        endPoint: "getLeadsFromFacebook",
        paramsData: paramsDataForLeads,
      });

      const paramsDataForRequests = {
        name: searchQuery || undefined,
        fromDate,
        toDate,
      };
      const responseFromSecondAPI = await getAPIHandler({
        endPoint: "getAllLeadsRequests",
        paramsData: paramsDataForRequests,
      });

      const combinedLeads = [];

      if (
        responseFromFirstAPI.data &&
        Array.isArray(responseFromFirstAPI.data.result)
      ) {
        const propertyDetails = responseFromFirstAPI.data.result;

        propertyDetails.forEach((property) => {
          const { propertyName } = property.PropertyDetails || {};
          const generatedLeads = property.PropertyDetails?.generatedLeads || [];

          generatedLeads.forEach((lead) => {
            const fieldData = lead.field_data || [];
            const leadInfo = {};

            fieldData.forEach((field) => {
              leadInfo[field.name] = field.values[0] || "Not Provided";
            });

            combinedLeads.push({
              id: lead.id || "Not Provided",
              propertyName: propertyName || "Not Provided",
              fullName: leadInfo["full_name"] || "Not Provided",
              phoneNumber: leadInfo["phone_number"] || "Not Provided",
              email: leadInfo["email"] || "Not Provided",
              createdTime: new Date(lead.created_time)
                .toLocaleString("en-IN", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  timeZone: "Asia/Kolkata",
                })
                .replace(",", ""),
              post_code: leadInfo["post_code"] || "Not Provided",
              preference:
                leadInfo["describe_your_preference_"] || "Not Provided",
              budget:
                leadInfo[
                  "are_you_interested_in_this_project_and_what_is_your_budget_?"
                ] || "Not Provided",
            });
          });
        });
      }

      if (
        responseFromSecondAPI.data &&
        Array.isArray(responseFromSecondAPI.data.result.allLeadsRequests)
      ) {
        const leadRequests = responseFromSecondAPI.data.result.allLeadsRequests;

        leadRequests.forEach((request) => {
          const {
            clientName,
            clientNumber,
            clientEmail,
            createdAt,
            remarks,
            minBudget,
            maxBudget,
          } = request;
          combinedLeads.push({
            id: request._id,
            propertyName: "Not Provided",
            fullName: clientName || "Not Provided",
            phoneNumber: clientNumber || "Not Provided",
            email: clientEmail || "Not Provided",
            createdTime: new Date(createdAt)
              .toLocaleString("en-IN", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                timeZone: "Asia/Kolkata",
              })
              .replace(",", ""),
            post_code: "Not Provided",
            preference: remarks || "Not Provided",
            budget: `${minBudget || "0"} - ${maxBudget || "0"}`,
          });
        });
      }
      setData(combinedLeads);
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const renderCellWithTooltip = (content, limit) => {
    if (content.length > limit) {
      return (
        <Tooltip title={content} placement="top" arrow>
          <span>{`${content.substring(0, limit)}...`}</span>
        </Tooltip>
      );
    }
    return content;
  };
  const rows = data.length > 0 ? data : [];
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <div>
      {loading ? (
        <Grid container justifyContent="center" mt={10}>
          <CircularProgress sx={{ color: "rgb(50,69,191)" }} />
        </Grid>
      ) : data.length < 1 ? (
        <DataNotFound />
      ) : (
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "15px", boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)" }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow sx={{ "& .MuiTableCell-root": { padding: "10px" } }}>
                {checkBox && (
                  <StyledTableCell align="left">Select</StyledTableCell>
                )}
                <StyledTableCell align="left">Property Name</StyledTableCell>
                <StyledTableCell align="left">Full Name</StyledTableCell>
                <StyledTableCell align="left">Phone Number</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">Post Code</StyledTableCell>
                <StyledTableCell align="left">Preference</StyledTableCell>
                <StyledTableCell align="left">Budget</StyledTableCell>
                <StyledTableCell align="left">Created Time</StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ "& .MuiTableCell-root": { padding: "11px" } }}>
              {rows.slice(startIndex, endIndex).map((row) => (
                <StyledTableRow key={row.id}>
                  {checkBox && (
                    <StyledTableCell align="left">
                      <Checkbox
                        onChange={(e) =>
                          onCheckboxChange(row.id, e.target.checked)
                        }
                        checked={selectedIds.includes(row.id)}
                      />
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="left">
                    {renderCellWithTooltip(row.propertyName, 20)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {renderCellWithTooltip(row.fullName, 20)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.phoneNumber}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {" "}
                    {renderCellWithTooltip(row.email, 20)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.post_code}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.preference}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {renderCellWithTooltip(row.budget, 8)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.createdTime}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <ReassignPop generatedLeadId={row.id} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {rowsPerPage > 1 && (
        <Grid container justifyContent="center" mt={3}>
          <Pagination
            color="success"
            count={Math.ceil(rows.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
          />
        </Grid>
      )}
    </div>
  );
}
