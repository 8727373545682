import React, { useState } from "react";
import SearchBar from "../SearchBar";
import { Grid, styled, Box, useTheme } from "@mui/material";
import ButtonComponent from "../Button";
import BasicDatePicker from "../DatePicker";
import Dropdown from "../FilterDropdown";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "10px",
  borderRadius: "15px",
  backgroundColor: "rgba(50,69,191, 0.05)",
  "& .displayEnd": {
    display: "flex",
    justifyContent: "flex-end",
    background: "grey",
    paddingTop: "10px",
    columnGap: "8px",
    [theme.breakpoints.down("md")]: {
      columnGap: "0px",
    },
  },
  "& .MuiInputBase-root": {
    color: "#0B1426 !important",
    height: "44px",
    backgroundColor: "transparent",
  },
  "& .MuiOutlinedInput-input": {
    color: "#0B1426",
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(1),
  },
  "& > *": {
    width: "100%",
  },
}));

const Filter = ({
  searchPlaceholder,
  onSearch,
  onFromDateChange,
  onToDateChange,
  onCategoryChange,
  onConstructionStatusChange,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [category, setCategory] = useState("");
  const [constructionStatus, setConstructionStatus] = useState("");
  const theme = useTheme();
  const handleSearch = (event, value) => {
    setSearchQuery(value);
    onSearch(value);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    onFromDateChange(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    onToDateChange(date);
  };
  const options = [
    { label: "Set Reminder", value: "SETREMINDER" },
    { label: "Busy", value: "BUSY" },
    { label: "Not Interested", value: "NOTINTERESTED" },
    { label: "Not Connected", value: "NOTCONNECTED" },
    { label: "Wrong Number", value: "WRONGNUMBER" },
  ];

  const handleConstructionStatusChange = (selectedValue) => {
    // Find the option object based on the selected value
    const selectedOption = options.find(
      (option) => option.label === selectedValue
    );

    if (selectedOption) {
      setConstructionStatus(selectedOption.value);
      onConstructionStatusChange(selectedOption.value); // Send the value like "SETREMINDER"
    }
  };

  const handleClear = () => {
    setSearchQuery("");
    setFromDate(null);
    setToDate(null);
    setCategory("");
    setConstructionStatus("");

    onSearch("");
    onFromDateChange(null);
    onToDateChange(null);
    onConstructionStatusChange("");
  };

  return (
    <StyledBox>
      <Grid container spacing={2} alignItems="center">
        <StyledGrid item xs={12} sm={6} md={4}>
          <SearchBar
            placeholder={searchPlaceholder}
            onSearch={handleSearch}
            value={searchQuery}
            options={[]}
          />
        </StyledGrid>

        <StyledGrid item xs={12} sm={6} md={2}>
          <BasicDatePicker
            label="From Date"
            onDateChange={handleFromDateChange}
            value={fromDate}
          />
        </StyledGrid>

        <StyledGrid item xs={12} sm={6} md={2}>
          <BasicDatePicker
            label="To Date"
            onDateChange={handleToDateChange}
            value={toDate}
          />
        </StyledGrid>
        <StyledGrid item xs={12} sm={6} md={2}>
          <Dropdown
            options={options.map((option) => option.label)} // Map to display only labels
            onChange={handleConstructionStatusChange} // onChange passes the selected value directly
            value={
              options.find((option) => option.value === constructionStatus)
                ?.label || ""
            } // Display the label based on the selected value
            placeholder="Call Disposition Status"
          />
        </StyledGrid>

        <StyledGrid item xs={12} sm={6} md={2}>
          <ButtonComponent
            name="Reset Filter"
            borderRadius="100px"
            backgroundColor="rgba(0, 0, 0, 0.05)"
            color="black"
            onClick={handleClear}
            width={"100%"}
            margin={"0px"}
            height={"41px"}
          />
        </StyledGrid>
      </Grid>
    </StyledBox>
  );
};

export default Filter;
