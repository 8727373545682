import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox, Grid, Pagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReassignPop from "../Popup/ReassignPopup";
import { format } from "date-fns";
import { IoEyeOutline } from "react-icons/io5";
import { getAPIHandler } from "../../config/service";
import DataNotFound from "../DataNotFound";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(50,69,191)",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(50,69,191, 0.05)",
  },
  "&:last-child td, &:last-child th": {},
  "& td ": {},
}));

export default function CustomizedTables(props) {
  const {
    searchQuery,
    fromDate,
    toDate,
    leadStatus,
    salesPersonId,
    checkBox,
    onCheckboxChange,
    selectedIds,
    visibleColumns,
    callDispositionStatus,
    location,
  } = props;
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({ page: 1, limit: 10 });
  const [loading, setLoading] = useState(false);
  const handleChangePage = (event, value) => {
    setFilter({ ...filter, page: value });
  };

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [
    filter,
    searchQuery,
    fromDate,
    toDate,
    leadStatus,
    salesPersonId,
    callDispositionStatus,
    location,
  ]);

  const getData = async () => {
    setLoading(true);
    try {
      const formattedFromDate = fromDate
        ? format(new Date(fromDate), "yyyy-MM-dd")
        : undefined;
      const formattedToDate = toDate
        ? format(new Date(toDate), "yyyy-MM-dd")
        : undefined;

      const paramsData = {
        ...filter,
        name: searchQuery || undefined,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        leadStatus: leadStatus || undefined,
        salesPersonId: salesPersonId || undefined,
        callDispositionStatus: callDispositionStatus || undefined,
        location: location || undefined,
      };

      const response = await getAPIHandler({
        endPoint: "getAllLeads",
        paramsData,
      });
      setData(response.data.result.allLeads);
      setTotalPages(response.data.result.totalPages || 0);
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const createRowsFromLeadsManagementData = (data) => {
    if (!data) {
      return [];
    }
    return data.map((management, index) => {
      const lastSixDigits = management._id.slice(-6);
      return {
        index: index + 1 + (filter.page - 1) * filter.limit,
        _id: management._id,
        LeadId: lastSixDigits,
        PropertyName: management.propertyDetails.propertyName,
        ClientName: management.clientName || "Not Provided",
        MobileNumber: management.clientNumber || "Not Provided",
        EmailAddress: management.clientEmail || "Not Provided",
        Location: management.location,
        LeadStatus: management.leadStatus || "Not Provided",
        CallDispositionStatus: management.callDispositionStatus,
        RequestedDateAndTime: new Date(management.createdAt)
          .toLocaleString("en-IN", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Asia/Kolkata",
          })
          .replace(",", ""),
      };
    });
  };

  const renderCellWithTooltip = (content, limit) => {
    if (content?.length > limit) {
      return (
        <Tooltip title={content}>
          <span>{`${content.substring(0, limit)}...`}</span>
        </Tooltip>
      );
    }
    return content;
  };

  const rows = data.length > 0 ? createRowsFromLeadsManagementData(data) : [];

  return (
    <div>
      {data.length < 1 ? (
        <DataNotFound />
      ) : (
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "15px", boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)" }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "10px",
                  },
                }}
              >
                {checkBox && (
                  <StyledTableCell align="left">Select</StyledTableCell>
                )}
                {visibleColumns.includes("PropertyName") && (
                  <StyledTableCell align="left">Property Name</StyledTableCell>
                )}
                {visibleColumns.includes("ClientName") && (
                  <StyledTableCell align="left">Client Name</StyledTableCell>
                )}
                {visibleColumns.includes("ClientNumber") && (
                  <StyledTableCell align="left">Client Number</StyledTableCell>
                )}
                {visibleColumns.includes("ClientEmail") && (
                  <StyledTableCell align="left">Client Email</StyledTableCell>
                )}
                {visibleColumns.includes("Location") && (
                  <StyledTableCell align="left">Location</StyledTableCell>
                )}
                {visibleColumns.includes("LeadStatus") && (
                  <StyledTableCell align="left">Lead Status</StyledTableCell>
                )}
                {visibleColumns.includes("CallDispositionStatus") && (
                  <StyledTableCell align="left">Call Status</StyledTableCell>
                )}
                {visibleColumns.includes("RequestedDateAndTime") && (
                  <StyledTableCell align="left">
                    Requested Date & Time
                  </StyledTableCell>
                )}
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  padding: "11px",
                },
              }}
            >
              {rows.map((row, index) => (
                <StyledTableRow key={index}>
                  {checkBox && (
                    <StyledTableCell align="left">
                      <Checkbox
                        onChange={(e) =>
                          onCheckboxChange(row._id, e.target.checked)
                        }
                        checked={selectedIds.includes(row._id)}
                      />
                    </StyledTableCell>
                  )}

                  {visibleColumns.includes("PropertyName") && (
                    <StyledTableCell align="left">
                      {renderCellWithTooltip(row.PropertyName, 25)}
                    </StyledTableCell>
                  )}

                  {visibleColumns.includes("ClientName") && (
                    <StyledTableCell align="left">
                      {row.ClientName}
                    </StyledTableCell>
                  )}

                  {visibleColumns.includes("ClientNumber") && (
                    <StyledTableCell align="left">
                      {row.MobileNumber}
                    </StyledTableCell>
                  )}
                  {visibleColumns.includes("ClientEmail") && (
                    <StyledTableCell align="left">
                      {renderCellWithTooltip(row.EmailAddress, 20)}
                    </StyledTableCell>
                  )}
                  {visibleColumns.includes("Location") && (
                    <StyledTableCell align="left">
                      {renderCellWithTooltip(row.Location, 20)}
                    </StyledTableCell>
                  )}

                  {visibleColumns.includes("LeadStatus") && (
                    <StyledTableCell align="left">
                      {" "}
                      {row.LeadStatus === "RESENTREQUEST"
                        ? "Resent Request"
                        : row.LeadStatus === "GENERATEDLEAD"
                        ? "Generated Lead"
                        : row.LeadStatus === "BOOKEDVISIT"
                        ? "Booked Visit"
                        : ""}
                    </StyledTableCell>
                  )}

                  {visibleColumns.includes("CallDispositionStatus") && (
                    <StyledTableCell align="left">
                      {row.CallDispositionStatus === "NOTINTERESTED"
                        ? "Not Interested"
                        : row.CallDispositionStatus === "NOTCONNECTED"
                        ? "Not Connected"
                        : row.CallDispositionStatus === "SETREMINDER"
                        ? "Set Reminder"
                        : row.CallDispositionStatus === "WRONGNUMBER"
                        ? "Wrong Number"
                        : row.CallDispositionStatus === "BUSY"
                        ? "Busy"
                        : row.CallDispositionStatus === "MARKASLEAD"
                        ? "Mark as Lead"
                        : row.CallDispositionStatus === "BOOKEDVISIT"
                        ? "Booked Visit"
                        : ""}
                    </StyledTableCell>
                  )}

                  {visibleColumns.includes("RequestedDateAndTime") && (
                    <StyledTableCell align="left">
                      {row.RequestedDateAndTime}
                    </StyledTableCell>
                  )}

                  <StyledTableCell>
                    <Grid
                      style={{
                        display: "flex",
                        gap: "0.6rem",
                        alignItems: "center",
                      }}
                    >
                      <IoEyeOutline
                        style={{ fontSize: "24" }}
                        onClick={() => {
                          if (row.LeadStatus === "Resent Request") {
                            navigate(`/leadsmanagement/leadsdetail/${row._id}`);
                          } else if (row.LeadStatus === "Generated Lead") {
                            navigate(
                              `/leadsmanagement/leadsdetailgenerated/${row._id}`
                            );
                          } else if (row.LeadStatus === "Booked Visit") {
                            navigate(
                              `/leadsmanagement/leadsdetailbooked/${row._id}`
                            );
                          }
                        }}
                      />
                      <ReassignPop requestedLeadId={row._id} />
                    </Grid>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalPages > 1 && (
        <Grid container justifyContent="center" mt={3}>
          <Pagination
            color="success"
            count={totalPages}
            page={filter.page}
            onChange={handleChangePage}
          />
        </Grid>
      )}
    </div>
  );
}
