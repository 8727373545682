import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Dropdown = ({
  options,
  onChange,
  value,
  width,
  color,
  backgroundColor,
  placeholder,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <FormControl>
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        displayEmpty
        renderValue={(selected) => {
          if (selected === "") {
            return <em>{placeholder}</em>;
          }
          return selected;
        }}
        sx={{
          width: isMobile ? "100%" : width || "100%",
          borderRadius: "100px",
          backgroundColor: backgroundColor || "rgba(0, 0, 0, 0.002)",
          color: color || "#CCC",
          cursor: "pointer",
          height: "3rem",
          "& svg": {
            color: "rgb(50,69,191)",
          },
        }}
        IconComponent={ExpandMoreIcon}
      >
        <MenuItem value="" disabled>
          <em>{placeholder}</em>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  width: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Dropdown;
