import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BubbleLoader from "../../component/Loader/BubbleLoader.js";
import FieldForInput from "../../component/input_field/FieldForInput.js";
import Button from "../../component/button/Button";
import { toast } from "react-hot-toast";

import "./ForgotPassword.css";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "../../schemas/index.js";
import { postAPIHandlerWithoutToken } from "../../config/service.js";
import AlternateAuthLayout from "../../layout/AlternateAuthLayout.js";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  const initialValues = {
    identifier: "",
  };

  const { values, errors, handleBlur, handleChange, touched, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: forgotPasswordSchema,
      onSubmit: async (values) => {
        setLoading(true);
        try {
          const response = await postAPIHandlerWithoutToken({
            endPoint: "forgotPassword",
            dataToSend: { email: values.identifier },
          });

          if (response.data.responseCode === 200) {
            const { otp } = response.data.result;
            toast.success("OTP Sent successfully");
            localStorage.setItem("otpTimer", 60);
            Navigate("/otpVerification", {
              state: { email: values.identifier, otp },
              replace: true,
            });
          } else {
            toast.error(response.data.responseMessage);
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error("Failed to send OTP. Please try again later.");
        }
        setLoading(false);
      },
    });

  return (
    <AlternateAuthLayout>
      <form className="admin-login-content" onSubmit={handleSubmit} action="">
        <div className="admin-login-div">
          <h2 className="admin-login-text">Forgot Password ?</h2>
          <p className="admin-login-msg">
            Please enter registered email/mobile number & we will send OTP to
            reset your password.
          </p>
        </div>
        <div>
          <label className="login-input-label">Email/Mobile Number</label>
          <FieldForInput
            className="login-field"
            name="identifier"
            placeholder="Enter email/mobile number"
            label="Email/Mobile number"
            width="572px"
            height="60px"
            borderRadius="100px"
            marginTop="10px"
            marginBottom="20px"
            border=" 1px solid rgba(225, 225, 225, 1)"
            onBlur={handleBlur}
            onChange={handleChange}
            maxLength={100}
            value={values.identifier}
          />
          {errors.identifier && touched.identifier ? (
            <p
              style={{
                color: "red",
                marginTop: "-40px",
                marginLeft: "20px",
                float: "left",
              }}
            >
              {errors.identifier}
            </p>
          ) : null}
        </div>

        <div className="login-button-div">
          <Button
            backgroundColor="rgb(50,69,191)"
            color="white"
            width="572px"
            height="60px"
            borderRadius="100px"
            border="none"
            type="submit"
            disabled={loading}
          >
            {loading ? <BubbleLoader color="#FFF" size="1rem" /> : "SEND"}
          </Button>
        </div>

        <div className="forgot-password-div">
          <Link
            to={"/"}
            style={{ textAlign: "center", textDecoration: "none" }}
            className="forgot-password-link"
          >
            <p className="forgot-password-text">Back to Login</p>
          </Link>
        </div>
      </form>
    </AlternateAuthLayout>
  );
};

export default ForgotPassword;
