import React, { useState } from "react";
import SearchBar from "../SearchBar";
import { Grid, styled, Box } from "@mui/material";
import ButtonComponent from "../Button";
import BasicDatePicker from "../DatePicker";
import Dropdown from "../FilterDropdown";
import { statesAndCities, leadOptions, callOptions } from "../../data/data";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "10px",
  borderRadius: "15px",
  backgroundColor: "rgba(50,69,191, 0.05)",
  "& .displayEnd": {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "rgba(0, 0, 0, 0.02)",
    paddingTop: "10px",
    columnGap: "8px",
    [theme.breakpoints.down("md")]: {
      columnGap: "0px",
    },
  },
  "& .MuiInputBase-root": {
    color: "#0B1426 !important",
    height: "44px",
    backgroundColor: "transparent",
  },
  "& .MuiOutlinedInput-input": {
    color: "#0B1426",
  },
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(1),
  },
  "& > *": {
    width: "100%",
  },
}));
const Filter = ({
  searchPlaceholder,
  onSearch,
  onFromDateChange,
  onToDateChange,
  onLeadStatusChange,
  onCallStatusChange,
  onStateChange,
  onCityChange,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [leadStatus, setLeadStatus] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const stateOptions = Object.keys(statesAndCities);
  const cityOptions = selectedState ? statesAndCities[selectedState] : [];

  const handleStateChange = (selectedValue) => {
    setSelectedState(selectedValue);
    onStateChange(selectedValue);
    setSelectedCity("");
  };

  const handleCityChange = (selectedValue) => {
    setSelectedCity(selectedValue);
    onCityChange(selectedValue);
  };

  const handleSearch = (event, value) => {
    setSearchQuery(value);
    onSearch(value);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    onFromDateChange(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    onToDateChange(date);
  };

  const handleLeadStatusChange = (selectedValue) => {
    const selectedOption = leadOptions.find(
      (option) => option.label === selectedValue
    );

    if (selectedOption) {
      setLeadStatus(selectedOption.value);
      onLeadStatusChange(selectedOption.value);
    }
  };

  const handleCallStatusChange = (selectedValue) => {
    const selectedOption = callOptions.find(
      (option) => option.label === selectedValue
    );

    if (selectedOption) {
      setCallStatus(selectedOption.value);
      onCallStatusChange(selectedOption.value);
    }
  };

  const handleClear = () => {
    setSearchQuery(""); // Clear search query
    setFromDate(null); // Clear fromDate
    setToDate(null); // Clear toDate
    setLeadStatus(""); // Reset category
    setCallStatus("");
    setSelectedState("");
    setSelectedCity("");

    onSearch(""); // Trigger onSearch with an empty query
    onFromDateChange(null); // Trigger onFromDateChange with null
    onToDateChange(null); // Trigger onToDateChange with null
    onLeadStatusChange("");
    onCallStatusChange("");
    onStateChange("");
    onCityChange("");
  };

  return (
    <StyledBox>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={6} md={4}>
          <SearchBar
            placeholder={searchPlaceholder}
            onSearch={handleSearch}
            value={searchQuery}
            options={[]}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <BasicDatePicker
            label="From Date"
            onDateChange={handleFromDateChange}
            value={fromDate} // Bind fromDate state to the value prop
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <BasicDatePicker
            label="To Date"
            onDateChange={handleToDateChange}
            value={toDate} // Bind toDate state to the value prop
          />
        </Grid>
        <StyledGrid item xs={12} sm={6} md={2}>
          <Dropdown
            options={leadOptions.map((option) => option.label)}
            onChange={handleLeadStatusChange}
            value={
              leadOptions.find((option) => option.value === leadStatus)
                ?.label || ""
            }
            placeholder="Lead Status"
          />
        </StyledGrid>

        <StyledGrid item xs={12} sm={6} md={2}>
          <Dropdown
            options={callOptions.map((option) => option.label)}
            onChange={handleCallStatusChange}
            value={
              callOptions.find((option) => option.value === callStatus)
                ?.label || ""
            }
            placeholder="Call Disposition Status"
          />
        </StyledGrid>

        <Grid item xs={12} sm={6} md={2}>
          <ButtonComponent
            name="Reset Filter"
            borderRadius="100px"
            backgroundColor="rgba(0, 0, 0, 0.05)"
            color="black"
            onClick={handleClear}
            width={"100%"}
            margin={"0px"}
            height={"45px"}
          />
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default Filter;

// <StyledGrid item xs={12} sm={6} md={2}>
// <Dropdown
//   options={stateOptions}
//   onChange={handleStateChange}
//   value={selectedState}
//   placeholder="Select State"
// />
// </StyledGrid>
// <StyledGrid item xs={12} sm={6} md={2}>
// <Dropdown
//   options={cityOptions}
//   onChange={handleCityChange}
//   value={selectedCity}
//   placeholder="Select City"
// />
// </StyledGrid>
