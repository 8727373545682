import React from "react";
import PropTypes from "prop-types";

import "./TotalNumCard.css";

const TotalNumCard = ({
  imageUrl,
  heading,
  number,
  backgroundColor,
  borderRadius,
  onClick,
}) => {
  return (
    <div
      className="total-num-card-container"
      style={{ backgroundColor, borderRadius }}
      onClick={onClick}
    >
      <div className="total-num-card-content total-num-card-content-left">
        <img
          className="total-num-card-img"
          src={imageUrl}
          alt="Card"
          style={{
            filter:
              "brightness(0) saturate(100%) hue-rotate(190deg) brightness(1.6)",
            width: "100%",
            height: "auto",
          }}
        />
      </div>
      <div className="total-num-card-content total-num-card-content-right">
        <h2 className="total-num-card-heading">{heading}</h2>
        <p className="total-num-card-number">{number}</p>
      </div>
    </div>
  );
};

TotalNumCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  borderRadius: PropTypes.string.isRequired,
  onClick: PropTypes.func, // Add onClick prop type
};

TotalNumCard.defaultProps = {
  onClick: () => {},
  // backgroundColor: "#f5fcf8", // Default no-op function
};

export default TotalNumCard;
