import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Box,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { Link, useNavigate, useParams } from "react-router-dom";
import ViewDetail from "../../../component/viewDetail/ViewDetail";
import MainLayout from "../../../layout/MainLayout";
import arrowBack from "../../../assets/arrowBack.svg";
import { getAPIHandler } from "../../../config/service";
import "./ViewDeveloper.css";
import typographyStyles from "../../../theme/Typography";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(50,69,191)",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(50,69,191, 0.05)",
  },
}));

const accountStyle = {
  accountHeading: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1%",
    gap: "1%",
  },
};

const ViewDeveloper = () => {
  const { id } = useParams();
  const [data, setData] = useState({});

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getDeveloperById",
        paramsData: { developerId: id },
      });
      setData(response.data.result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  const developerDetails = [
    { left: "Developer ID", value: data?._id },
    { left: "Full Name", value: data?.fullName },
    {
      left: "Created At",
      value: data
        ? new Date(data.createdAt).toLocaleString("en-IN", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Asia/Kolkata",
          })
        : null,
    },
    {
      left: "Status",
      value: data && (
        <span style={{ color: data.status === "ACTIVE" ? "green" : "red" }}>
          {data.status}
        </span>
      ),
    },
  ];

  return (
    <MainLayout>
      <Grid container direction="column" sx={{ padding: 2 }}>
        <Grid item sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography variant="h4" sx={{ fontWeight: 700 }}>
            Developer Management
          </Typography>
        </Grid>
        <Grid item sx={{ ml: 2, mb: 3 }}>
          <Paper elevation={0} sx={{ padding: 2 }}>
            <Box sx={accountStyle.accountHeading}>
              <Link to="/developermanagement">
                <img
                  src={arrowBack}
                  alt="arroWback"
                  style={{
                    width: "2.813rem",
                    height: "2.813rem",
                    cursor: "pointer",
                  }}
                />
              </Link>
              <Typography
                variant="h5"
                sx={{ marginLeft: "1rem", fontWeight: 800 }}
              >
                Developer Details
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                border: "1px solid black",
                borderRadius: "15px",
              }}
            >
              <ViewDetail type="developer" details={developerDetails} />
            </Box>

            <h4>Developed Property</h4>

            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "15px",
                boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Table aria-label="developer properties">
                <TableHead>
                  <TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        padding: "10px",
                      },
                    }}
                  >
                    <StyledTableCell>Property Name</StyledTableCell>
                    <StyledTableCell>Category</StyledTableCell>
                    <StyledTableCell>Form</StyledTableCell>
                    <StyledTableCell>Construction Status</StyledTableCell>
                    <StyledTableCell>Location</StyledTableCell>
                    <StyledTableCell>Price Range</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "& .MuiTableCell-root": {
                      padding: "11px",
                    },
                  }}
                >
                  {data.properties?.length > 0 ? (
                    data.properties.map((property) => (
                      <StyledTableRow key={property._id} hover>
                        <StyledTableCell>
                          {property.propertyName}
                        </StyledTableCell>
                        <StyledTableCell>{property.category}</StyledTableCell>
                        <StyledTableCell>{property.form}</StyledTableCell>
                        <StyledTableCell>
                          {property.constructionStatus}
                        </StyledTableCell>
                        <StyledTableCell>{`${property.city}, ${property.state}, ${property.country}`}</StyledTableCell>
                        <StyledTableCell>{`${property.minPrice} - ${property.maxPrice}`}</StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            color={
                              property.status === "ACTIVE" ? "green" : "red"
                            }
                          >
                            {property.status}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={7} align="center">
                        No properties available
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ViewDeveloper;
