import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Pagination,
  TableCell,
} from "@mui/material";

import { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { BiEditAlt, BiBlock } from "react-icons/bi";
import { getAPIHandler } from "../../config/service";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import LogoutPopup from "../../component/Popup/Logoutpopup";
import DataNotFound from "../DataNotFound";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(50,69,191)",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(50,69,191, 0.05)",
  },
}));

export default function CustomizedTables(props) {
  const { searchQuery, fromDate, toDate, setDownloadCSVFunction } = props;
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
  const [selectedDeveloperId, setSelectedDeveloperId] = useState(null);
  const [selectedDeveloperStatus, setSelectedDeveloperStatus] = useState(null);
  const [filter, setFilter] = useState({ page: 1, limit: 10 });

  const navigate = useNavigate();

  useEffect(() => {
    getData();
    setDownloadCSVFunction(() => handleDownloadCSV);
  }, [filter, searchQuery, fromDate, toDate, setDownloadCSVFunction]);

  const getData = async () => {
    try {
      const formattedFromDate = fromDate
        ? format(new Date(fromDate), "yyyy-MM-dd")
        : undefined;
      const formattedToDate = toDate
        ? format(new Date(toDate), "yyyy-MM-dd")
        : undefined;

      const paramsData = {
        ...filter,
        name: searchQuery || undefined,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      };

      const response = await getAPIHandler({
        endPoint: "listAllDevelopers",
        paramsData,
      });

      setData(response.data.result.developers);
      setTotalPages(response.data.result.totalPages || 0);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChangePage = (event, value) => {
    setFilter({ ...filter, page: value });
  };

  const handleLogoutClick = (developerId, status) => {
    setIsLogoutPopupOpen(true);
    setSelectedDeveloperId(developerId);
    setSelectedDeveloperStatus(status);
  };

  const handleLogoutConfirm = () => {
    setIsLogoutPopupOpen(false);
    setSelectedDeveloperId(null);
  };

  const handleViewClick = (developerId) => {
    navigate(`/developermanagement/viewdeveloper/${developerId}`);
  };

  const handleEditClick = (developerId) => {
    navigate(`/developermanagement/editdeveloper/${developerId}`);
  };

  const handleDownloadCSV = () => {
    const headers = [
      "S.No.",
      "Developer ID",
      "Developer Name",
      "Created Date & Time",
      "Status",
    ];

    const csvContent = [
      headers.join(","),
      ...data.map((row, index) =>
        [
          index + 1 + (filter.page - 1) * filter.limit,
          row._id,
          row.fullName,
          new Date(row.createdAt).toLocaleString("en-IN", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Asia/Kolkata",
          }),
          row.status,
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const timestamp = format(new Date(), "yyyy-MM-dd_HH:mm");
    saveAs(blob, `developer_list_${timestamp}.csv`);
  };

  const createRowsFromDeveloperManagementData = (data) => {
    if (!data) {
      return [];
    }
    return data.map((management, index) => ({
      index: index + 1 + (filter.page - 1) * filter.limit,
      _id: management._id,
      developerId: management._id.slice(-6),
      fullName: management.fullName,
      createdAt: new Date(management.createdAt)
        .toLocaleString("en-IN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Kolkata",
        })
        .replace(",", ""),
      status: management.status,
    }));
  };

  const rows =
    data?.length > 0 ? createRowsFromDeveloperManagementData(data) : [];

  return (
    <div>
      {!data ? (
        <DataNotFound />
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "15px",
            boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "10px",
                  },
                }}
              >
                <StyledTableCell align="left">S.No.</StyledTableCell>
                <StyledTableCell align="left">Developer ID</StyledTableCell>
                <StyledTableCell align="left">Developer Name</StyledTableCell>
                <StyledTableCell align="left">
                  Created Date & Time
                </StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  padding: "11px",
                },
              }}
            >
              {rows.map((row) => (
                <StyledTableRow key={row._id}>
                  <StyledTableCell align="left">{row.index}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.developerId}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.fullName}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.createdAt}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    style={{ color: row.status === "ACTIVE" ? "green" : "red" }}
                  >
                    {row.status}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Grid container alignItems="center" gap={1}>
                      <IoEyeOutline
                        onClick={() => handleViewClick(row._id)}
                        style={{ fontSize: "24px" }}
                      />
                      <BiEditAlt
                        onClick={() => handleEditClick(row._id)}
                        style={{ fontSize: "24px" }}
                      />
                      <BiBlock
                        onClick={() => handleLogoutClick(row._id, row.status)}
                        style={{
                          color: row.status === "ACTIVE" ? "green" : "red",
                          fontSize: "24px",
                        }}
                      />
                    </Grid>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {isLogoutPopupOpen && (
        <LogoutPopup
          isOpen={isLogoutPopupOpen}
          onClose={handleLogoutConfirm}
          name={
            selectedDeveloperStatus === "ACTIVE"
              ? "Block Developer?"
              : "Unblock Developer?"
          }
          description={
            selectedDeveloperStatus === "ACTIVE"
              ? "Are you sure you want to block this developer?"
              : "Are you sure you want to unblock this developer?"
          }
          cancelbtn="No"
          okbtn={selectedDeveloperStatus === "ACTIVE" ? "Block" : "Unblock"}
          route="developer"
          developerId={selectedDeveloperId}
          data={data}
          setData={setData}
          type="developer"
        />
      )}

      {totalPages > 1 && (
        <Grid container justifyContent="center" mt={3}>
          <Pagination
            color="success"
            count={totalPages}
            page={filter.page}
            onChange={handleChangePage}
          />
        </Grid>
      )}

      <button
        id="developer-tablesdata-download"
        style={{ display: "none" }}
        onClick={handleDownloadCSV}
      >
        Download CSV
      </button>
    </div>
  );
}
