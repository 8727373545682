import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BubbleLoader from "../../component/Loader/BubbleLoader.js";
import FieldForInput from "../../component/input_field/FieldForInput.js";
import Button from "../../component/button/Button";
import "./ResetPassword.css";
import { useFormik } from "formik";
import { resetPasswordSchema } from "../../schemas/index.js";
import { toast } from "react-hot-toast";
import { postAPIHandlerWithoutToken } from "../../config/service.js";
import AuthLayout from "../../layout/AuthLayout.js";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const email = location?.state?.email;
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const navigate = useNavigate();

  const { values, errors, handleBlur, handleChange, touched, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: resetPasswordSchema,
      onSubmit: async (values) => {
        setLoading(true);
        try {
          const response = await postAPIHandlerWithoutToken({
            endPoint: "resetPassword",
            dataToSend: {
              email: email.toLocaleLowerCase(),
              newPassword: values.password,
            },
          });

          if (response && response.data.responseCode === 200) {
            toast.success("Password reset successfully.");

            navigate("/");
          } else {
            toast.error(response.data.responseMessage);
          }
        } catch (error) {
          toast.error("Failed to reset password. Please try again later.");
          console.error("Error:", error);
        }
        setLoading(false);
      },
    });

  return (
    <AuthLayout>
      <div className="login-content login-content-right">
        <form className="admin-login-content" onSubmit={handleSubmit} action="">
          <div className="admin-login-div">
            <h2 className="admin-login-text">Reset Password</h2>
            <p className="admin-login-msg">Enter your new password</p>
          </div>
          <div>
            <label className="login-input-label">Password</label>
            <FieldForInput
              className="login-field"
              name="password"
              type="password"
              placeholder="Enter password"
              label="Password"
              width="572px"
              height="60px"
              borderRadius="100px"
              border=" 1px solid rgba(225, 225, 225, 1)"
              onBlur={handleBlur}
              onChange={handleChange}
              restrictCopypaste={true}
              maxLength={20}
              value={values.password}
            />
            {errors.password && touched.password ? (
              <p
                style={{
                  color: "red",
                  marginTop: "-40px",
                  marginLeft: "20px",
                  float: "left",
                }}
              >
                {errors.password}
              </p>
            ) : null}
          </div>
          <div>
            <label className="login-input-label">Confirm Password</label>
            <FieldForInput
              className="login-field"
              name="confirmPassword"
              type="password"
              placeholder="Enter Confirm Password"
              label="Confirm Password"
              width="572px"
              height="60px"
              borderRadius="100px"
              border=" 1px solid rgba(225, 225, 225, 1)"
              onBlur={handleBlur}
              onChange={handleChange}
              maxLength={20}
              restrictCopypaste={true}
              value={values.confirmPassword}
            />
            {errors.confirmPassword && touched.confirmPassword ? (
              <p
                style={{
                  color: "red",
                  marginTop: "-40px",
                  marginLeft: "20px",
                  float: "left",
                }}
              >
                {errors.confirmPassword}
              </p>
            ) : null}
          </div>

          <div className="login-button-div">
            <Button
              backgroundColor="rgb(50,69,191)"
              color="white"
              width="572px"
              height="60px"
              borderRadius="100px"
              border="none"
              type="submit"
              disabled={loading}
            >
              {loading ? <BubbleLoader color="#FFF" size="1rem" /> : "SUBMIT"}
            </Button>
          </div>
          <div className="forgot-password-div">
            <Link
              to={"/"}
              style={{ textAlign: "center", textDecoration: "none" }}
              className="forgot-password-link"
            >
              <p className="forgot-password-text">Back to Login</p>
            </Link>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
