import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Pagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAPIHandler } from "../../config/service";
import { format } from "date-fns";
import DataNotFound from "../DataNotFound";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(50,69,191)",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(50,69,191, 0.05)",
  },
  "&:last-child td, &:last-child th": {},
  "& td ": {},
}));

export default function CallTable(props) {
  const { executiveId, fromDate, toDate, searchQuery, callDispositionStatus } =
    props;

  const [data, setData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [filter, setFilter] = React.useState({
    page: 1,
    limit: 10,
  });
  const navigate = useNavigate();

  const handleChangePage = (event, value) => {
    setFilter({ ...filter, page: value });
  };

  React.useEffect(() => {
    getData();
  }, [filter, searchQuery, fromDate, toDate, callDispositionStatus]);

  const getData = async () => {
    try {
      const formattedFromDate = fromDate
        ? format(new Date(fromDate), "yyyy-MM-dd")
        : undefined;
      const formattedToDate = toDate
        ? format(new Date(toDate), "yyyy-MM-dd")
        : undefined;
      const paramsData = {
        ...filter,
        executiveId: executiveId,
        name: searchQuery || undefined,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        callDispositionStatus: callDispositionStatus || undefined,
      };

      const response = await getAPIHandler({
        endPoint: "getAllCallDisposition",
        paramsData,
      });
      setData(response.data.result.callDisposition);
      setTotalPages(response.data.result.totalPages || 0);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderCellWithTooltip = (content, limit) => {
    if (content && content.length > limit) {
      return (
        <Tooltip title={content}>
          <span>{content.substring(0, limit)}...</span>
        </Tooltip>
      );
    }
    return content || "";
  };

  const createRowsFromPropertyManagementData = (data) => {
    return data.map((management, index) => {
      return {
        index: index + 1,
        _id: management._id,
        propertyName: management.propertyDetails?.propertyName,
        createdAt: new Date(management.createdAt).toLocaleString("en-IN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Kolkata",
        }),
        ClientName: management?.clientDetails?.clientName,
        constructionStatus: management.callDispositionStatus,
        clientNumber: management?.clientDetails?.clientNumber,
        clientEmail: management?.clientDetails?.clientEmail,
      };
    });
  };

  const rows =
    data.length > 0 ? createRowsFromPropertyManagementData(data) : [];

  return (
    <div>
      {data.length < 1 ? (
        <DataNotFound />
      ) : (
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "15px", boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)" }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "10px",
                  },
                }}
              >
                <StyledTableCell align="left">S.No.</StyledTableCell>
                <StyledTableCell align="left">Property Name</StyledTableCell>
                <StyledTableCell align="left">Client Name</StyledTableCell>
                <StyledTableCell align="left">Client Email</StyledTableCell>
                <StyledTableCell align="left">Client Number</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">Created At</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  padding: "11px",
                },
              }}
            >
              {rows.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left">{row.index}</StyledTableCell>
                  <StyledTableCell align="left">
                    {renderCellWithTooltip(row.propertyName, 20)}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {row.ClientName}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.clientEmail}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.clientNumber}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.constructionStatus === "NOTINTERESTED"
                      ? "Not Interested"
                      : row.constructionStatus === "NOTCONNECTED"
                      ? "Not Connected"
                      : row.constructionStatus === "SETREMINDER"
                      ? "Set Reminder"
                      : row.constructionStatus === "WRONGNUMBER"
                      ? "Wrong Number"
                      : row.constructionStatus === "BUSY"
                      ? "Busy"
                      : ""}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {row.createdAt}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {totalPages > 1 && (
        <Grid container justifyContent="center" mt={3}>
          <Pagination
            color="success"
            count={totalPages}
            page={filter.page}
            onChange={handleChangePage}
          />
        </Grid>
      )}
    </div>
  );
}
